
import {useState} from "react";
import "./app.css";
import backarrow from "./img/recycle.png";
import meImg from "./img/me.jpeg";
import meImg1 from "./img/imgLN.png";

function Contattami(){
  const [flipped, setFlip] = useState(false);

  return(
      <div id="contattami-main" className="card"
           onMouseEnter={()=>setFlip(true)} onMouseLeave={()=>setFlip(false)}>
          {flipped ?
          (
              <div className="card-back">
                  <img id="backarrow" src={backarrow} onClick={()=>setFlip(!flipped)}/>

                  <div className="card-text">

                  <p>Guarda i miei profili su:
                      <ul>
                      <li><a href="https://www.linkedin.com/in/alessandro-nocera-2865a4198/" target="_blank">Linkedin</a></li>
                      <li><a href="https://github.com/NOC3" target="_blank">GitHub</a></li>
                      </ul>
                  </p>
                  <p>Oppure scrivimi a:
                      <ul>
                      <li>an.alessandronocera@gmail.com</li>
                      </ul>
                  </p>
                      <div className={"my-img-area"}>
                          <div className={"my-img-1"}>
                              <div className={"descr"}>Questo sono io con i capelli lunghi</div>
                              <img id="meImg" src={meImg}/>
                          </div>
                            <div className={"my-img-2"}>
                                <div className={"descr"}>
                                    Questo sono io alla laurea
                                </div>
                                <img id="meImg" src={meImg1}/>
                            </div>

                      </div>
                  </div>
              </div>
          ):(
              <div className="card-front" >
                <div>Contattami!</div>
              </div>
          )}
      </div>
  );
}

function Cosafaccio(){
    const [flipped, setFlip] = useState(false);
    return(
        <div id="cosafaccio-main" className="card"
             onMouseEnter={()=>setFlip(true)} onMouseLeave={()=>setFlip(false)}>
            {flipped ?
            (
                <div  className="card-back">
                    <img id="backarrow" src={backarrow} onClick={()=>setFlip(!flipped)}/>
                    <div className="card-text">
                        <p>Sono stato sono responsabile della progettazione e sviluppo di un gestionale e un applicativo di calcolo finanziario, entrambi a livello semi-amatoriale</p>
                        <p>Attualmente sto frequentando un master in cloud computing </p>
                        <p>Ho partecipato a vari corsi riguardanti l'ambito gestionale e la creazione di startup e ho partecipato a un paio di hackaton, mi piace mettermi alla prova</p>
                        <p>Ora ho voglia di mettermi in gioco, testarmi e acquisire competenze nuove</p>
                    </div>
                </div>
            ):(
                <div className="card-front" onClick={()=>setFlip(!flipped)} >
                    <div>Cosa faccio?</div>
                </div>
            )}
        </div>
    );
}

function Chisono(){
    const [flipped, setFlip] = useState(false);

    return(
        <div id="chisono-main" className="card"
             onMouseEnter={()=>setFlip(true)} onMouseLeave={()=>setFlip(false)}>
        {flipped ?
            (
                <div  className="card-back">
                    <img id="backarrow" src={backarrow} onClick={()=>setFlip(!flipped)}/>

                        <div className="card-text">
                        <p>Piacere, sono Alessandro Nocera, sono nato il 14 agosto 1999, mi sono diplomato nel 2018 al il liceo scientifico M. Curie a Pinerolo
                            successivamente mi sono laureato in Informatica presso l'Università di Torino</p>
                        <p>
                            Ho molte passioni e interessi in svariati ambiti, sia di materie scientifiche che umanistiche, ma principalmente sono innamorato dell'informatica,
                            mi appassiona l'ethical hacking, un po' di robotica ma soprattutto la gestione e delle reti e lo sviluppo di applicazioni distribuite.
                        </p>
                        </div>

                </div>
            ):(
                <div className="card-front"onClick={()=>setFlip(!flipped)} >
                    <div>Chi sono?</div>
                </div>
            )}
        </div>
    );
}

function Saluto(){

    return(
            <div id="saluto-main" className="card-static">
                <div>
                <h2>Ciao!</h2>
                <p>Benvenuto sulla mia pagina</p>
                <p>Sono Alessando Nocera, forse avrai delle domande su di me</p>
                <p>clicca sulle card per girarle e scoprire le risposte alle domande</p>
                </div>
            </div>
    );
}

function App() {
  return (
  <div id="main">
      <div className="col">
          <Saluto/>
          <Chisono/>
      </div>
      <div className="col">
          <Cosafaccio/>
          <Contattami/>
      </div>


  </div>
  );
}

export default App;
